"use strict";

require("core-js/modules/es.object.define-property.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});

var vue_1 = require("vue");

exports["default"] = (0, vue_1.defineComponent)({
  name: 'PasswordInput',
  inheritAttrs: false,
  props: {
    value: {
      type: String,
      "default": ''
    }
  },
  data: function data() {
    return {
      showPassword: false
    };
  },
  methods: {
    updateValue: function updateValue(newValue) {
      this.$emit('input', newValue);
    }
  }
});